import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Paper,
  makeStyles,
  Typography,
  Grid,
  Container,
} from "@material-ui/core";
import {
  //ListAlt as ListAltIcon,
  MenuBook as MenuBookIcon,
} from "@material-ui/icons";
import ReactPlayer from "react-player";
import {
  getParticularCourse,
  getCourseDocuments,
} from "../../../redux/actions/CoursesActions";
import PageHeader from "../../../reusableComponents/PageHeader";
import isEmpty from "../../../reusableComponents/is-empty";
import ButtonField from "../../../reusableComponents/ButtonField";
import { Tab, Tabs } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import FileView from "../../Courses/FileViews";
import { deleteDocument } from "../../../redux/actions/CoursesActions";
import DialogModal from "../../../reusableComponents/Modal/DialogModal";
import VideoEdit from "./VideoEdit";
import EditDocument from "../AddDocuments/EditDocument";

import Button from "@material-ui/core/Button";
import CourseEdit from "./CourseEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageRoot: {
    backgroundColor: "#fdfdff",
    margin: theme.spacing(1, 0),
    height: 200,
  },
  pageContent: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  page: {
    marginTop: theme.spacing(2),
  },
  pageInnerContent: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  pageText: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    fontWeight: 600,
    color: "#0F7C90",
    display: "flex",
    width: "100%",
    height: 90,
    justifyContent: "center",
    alignItems: "center",
  },

  buttonStyle1: {
    fontWeight: 600,
    color: "#fdfdff",
    backgroundColor: "#003366",
    display: "flex",
    margin: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  buttonStyle2: {
    display: "flex",
    margin: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },

  heading: {
    fontWeight: 600,
  },
  margin: {
    margin: theme.spacing(2),
  },
  link: {
    fontWeight: 600,
    color: "#0F7C90",
    display: "flex",
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  linkStyle: {
    background: "#0F7C90",
    fontWeight: 600,
  },
  tabs: {
    backgroundColor: "#FFFFFF",
    color: "black",
  },
  tabList: {
    "& .MuiTabs-indicator": {
      display: "none",
      backgroundColor: "#0F7C90",
    },
  },

  tabs2: {
    backgroundColor: "#FF5733",
    fontWeight: 600,
    margin: theme.spacing(2),
  },
  tabs3: {
    backgroundColor: "#003366",
    fontWeight: 600,
    margin: theme.spacing(2),
  },

  flexContainer: {
    height: "60px",
    background: "lightblue",
  },
  selected1: {
    background: "#003399",
    color: "white",
  },
  selected2: {
    background: "maroon",
    color: "white",
  },
  selected3: {
    background: "purple",
    color: "white",
  },
  btns: {
    fontWeight: 600,
    color: "white",
    backgroundColor: "#003399",
    //display: "flex",
    // margin: theme.spacing(2),
    //marginTop: "20px",
    // marginLeft: "900px",
    //marginBottom: "36px",
    "&:hover": {
      backgroundColor: "#003399",
      //opacity: "1",
    },
  },

  // btns:{
  //   fontWeight: 600,
  //   color: "#fdfdff",
  //   backgroundColor: "#003366",
  //   //display: "flex",
  //   // margin: theme.spacing(2),
  //   //marginTop: "20px",
  //   marginLeft: "900px",
  //   //marginBottom: "36px",
  //   "&:hover": {
  //     backgroundColor: "#008dd2",
  //     //opacity: "1",
  //   },
  // }
}));

const IndividualCourse = ({
  getParticularCourse,
  getCourseDocuments,
  match,
  courses,
  history,
  deleteDocument,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [editDocumentOrderPopup, setEditDocumentOrderPopup] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [fileData, setFileData] = useState({});

  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [file, setFile] = React.useState("");
  const [documentName, setDocumentName] = React.useState("");

  const { courseId } = match.params;
  const { particularCourse, documents } = courses;
  useEffect(() => {
    getParticularCourse(courseId);
    getCourseDocuments(courseId);
  }, []);

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleFile = (data) => {
    // console.log(data);
    setFile(data?.document?.link);
    setDocumentName(data?.document?.name);
    setOpenPopup(true);
  };

  const handleEdit = (video) => {
    setEditPopup(true);
    setVideoData(video);
    // console.log(video);
  };

  const handleDocumentOrderEdit = (file) => {
    setEditDocumentOrderPopup(true);
    setFileData(file);
    // console.log(file);
  };

  const handleDocumentDelete = async (file) => {
    const docData = {
      courseId: file?.courseId?._id,
      documentId: file?._id,
    };
    await deleteDocument(docData);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.pageContent}>
          <div
            style={{
              display: "flex",
              alignItems: "center",

              justifyContent: "space-between",
            }}
          >
            <div style={{ alignSelf: "center" }}>
              <PageHeader
                title={particularCourse?.title}
                icon={<MenuBookIcon fontSize="large" />}
              />
            </div>
            <div>
              {particularCourse?.videos?.length > 1 && Number(value) === 1 && (
                <Grid container spacing={2} align="left">
                  <Grid item sx={5} md={5}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        history.push(`/admin/rearrangecourse/${courseId}`)
                      }
                      className={classes.btns}
                    >
                      Rearrange Course
                    </Button>
                  </Grid>
                  <Grid item sx={5} md={5}>
                    <CourseEdit courseData={particularCourse} />
                  </Grid>
                </Grid>
              )}
              {documents &&
                documents.filter((file) => file?.documentType === "Document")
                  .length > 1 &&
                Number(value) === 2 && (
                  <Grid container spacing={2} align="left">
                    <Grid item sx={1} md={1}></Grid>
                    <Grid item sx={2} md={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/RearrangeDocs/${courseId}`, // Only pass courseId in the URL
                            state: {
                              documents,
                              particularCourse,
                              dataType: "document",
                            }, // Pass documents via state
                          })
                        }
                        className={classes.btns}
                      >
                        Rearrange Documents
                      </Button>
                    </Grid>
                  </Grid>
                )}
              {documents &&
                documents.filter((file) => file?.documentType === "Scenarios")
                  .length > 1 &&
                Number(value) === 3 && (
                  <Grid container spacing={2} align="left">
                    <Grid item sx={1} md={1}></Grid>
                    <Grid item sx={2} md={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/RearrangeDocs/${courseId}`, // Only pass courseId in the URL
                            state: {
                              documents,
                              particularCourse,
                              dataType: "scenarios",
                            }, // Pass documents via state
                          })
                        }
                        className={classes.btns}
                      >
                        Rearrange Scenarios
                      </Button>
                    </Grid>
                  </Grid>
                )}
            </div>
          </div>
        </Paper>
      </Container>
      {/* {particularCourse?.videos?.length > 1 && Number(value) === 1 && (
        <Grid container spacing={2} align="left">
          <Grid item sx={1} md={1}></Grid>
          <Grid item sx={2} md={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/admin/rearrangecourse/${courseId}`)}
              className={classes.btns}
            >
              Rearrange Course
            </Button>
          </Grid>
          <Grid item sx={2} md={2}>
            <CourseEdit courseData={particularCourse} />
          </Grid>
        </Grid>
      )} */}
      {/* {documents &&
        documents.filter((file) => file?.documentType === "Document").length >
          1 &&
        Number(value) === 2 && (
          <Grid container spacing={2} align="left">
            <Grid item sx={1} md={1}></Grid>
            <Grid item sx={2} md={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  history.push({
                    pathname: `/admin/RearrangeDocs/${courseId}`, // Only pass courseId in the URL
                    state: {
                      documents,
                      particularCourse,
                      dataType: "document",
                    }, // Pass documents via state
                  })
                }
                className={classes.btns}
              >
                Rearrange Documents
              </Button>
            </Grid>
          </Grid>
        )} */}

      {/* {documents &&
        documents.filter((file) => file?.documentType === "Scenarios").length >
          1 &&
        Number(value) === 3 && (
          <Grid container spacing={2} align="left">
            <Grid item sx={1} md={1}></Grid>
            <Grid item sx={2} md={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  history.push({
                    pathname: `/admin/RearrangeDocs/${courseId}`, // Only pass courseId in the URL
                    state: {
                      documents,
                      particularCourse,
                      dataType: "scenarios",
                    }, // Pass documents via state
                  })
                }
                className={classes.btns}
              >
                Rearrange Scenarios
              </Button>
            </Grid>
          </Grid>
        )} */}

      <Container maxWidth="lg">
        <Paper elevation={0} square className={classes.root}>
          <div className={classes.page}>
            {/* <Typography variant="h6" component="div" className={classes.heading} >Course Videos</Typography>  */}

            <TabContext value={value}>
              <AppBar position="static" className={classes.tabs}>
                <TabList onChange={handleChange} className={classes.tabList}>
                  <Tab
                    label="Course videos"
                    value="1"
                    classes={{
                      selected: classes.selected1,
                    }}
                    className={classes.tabs}
                  />
                  <Tab
                    label="Documents"
                    value="2"
                    classes={{
                      selected: classes.selected1,
                    }}
                  />
                  <Tab
                    label="Scenarios"
                    value="3"
                    classes={{
                      selected: classes.selected1,
                    }}
                  />
                </TabList>
              </AppBar>

              <TabPanel value="1">
                {particularCourse?.videos.map((list, idx) => {
                  const regex = /www.youtube-nocookie.com/g;
                  return (
                    <Paper className={classes.pageRoot}>
                      <Grid container className={classes.pageInnerContent}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.pageText}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            backgroundColor="#FFFFFF"
                            className={classes.title}
                          >
                            {idx + 1}. {list?.title}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.button}
                        >
                          <ButtonField
                            buttonStyle={classes.buttonStyle1}
                            name="Edit"
                            onClick={() => handleEdit(list)}
                          />

                          {/* <ButtonField
                             buttonStyle={classes.buttonStyle2}
                             name="Hide" 
                           
                            />   */}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.pageText}
                        >
                          {isEmpty(
                            list?.embedUrl
                          ) ? null : list?.embedUrl.match(regex) ? (
                            <ReactPlayer
                              className="react-player"
                              config={{
                                file: {
                                  attributes: {
                                    controlsList: "nodownload",
                                  },
                                },
                              }}
                              url={list?.embedUrl}
                              width="100%"
                              height="100%"
                              controls
                            />
                          ) : (
                            <div className={classes.link}>
                              <ButtonField
                                name="Link"
                                buttonStyle={classes.linkStyle}
                                onClick={() =>
                                  window.open(list?.embedUrl, "_blank")
                                }
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })}
              </TabPanel>

              <TabPanel value="2">
                {documents && documents.length > 0 ? (
                  documents
                    ?.filter((file) => file?.documentType === "Document")
                    ?.sort((a, b) => a.orderId - b.orderId)
                    ?.map((list, idx) => (
                      <div key={list._id} className={classes.courseDisplay}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          className={classes.title}
                          onClick={() => handleFile(list)}
                        >
                          {idx + 1}. {list?.document?.name}
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <ButtonField
                            buttonStyle={classes.buttonStyle1}
                            name="Edit Document"
                            onClick={() => handleDocumentOrderEdit(list)}
                          />
                          <ButtonField
                            buttonStyle={classes.buttonStyle1}
                            name="Delete Document"
                            onClick={() => handleDocumentDelete(list)}
                          />
                        </div>
                      </div>
                    ))
                ) : (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className={classes.courseTitle}
                  >
                    No Documents found
                  </Typography>
                )}
              </TabPanel>

              <TabPanel value="3">
                {documents && documents.length > 0 ? (
                  documents
                    ?.filter((file) => file?.documentType === "Scenarios")
                    ?.sort((a, b) => a.orderId - b.orderId)
                    ?.map((list, idx) => (
                      <div key={idx} className={classes.courseDisplay}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          className={classes.title}
                          onClick={() => handleFile(list)}
                        >
                          {idx + 1}. {list?.document?.name}
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <ButtonField
                            buttonStyle={classes.buttonStyle1}
                            name="Edit Scenario"
                            onClick={() => handleDocumentOrderEdit(list)}
                          />
                          <ButtonField
                            buttonStyle={classes.buttonStyle1}
                            name="Delete Scenario"
                            onClick={() => handleDocumentDelete(list)}
                          />
                        </div>
                      </div>
                    ))
                ) : (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className={classes.courseTitle}
                  >
                    No Documents found
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </div>
        </Paper>
      </Container>

      <DialogModal
        title={
          documentName ? (
            <a target="_blank" href={file} download={documentName}>
              Download - {documentName}
            </a>
          ) : (
            "Document"
          )
        }
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <div style={{ width: "1000px", height: "calc(100% - 42px)" }}>
          <FileView file={file} />
        </div>
      </DialogModal>

      <DialogModal
        title="Edit Videos"
        openPopup={editPopup}
        setOpenPopup={setEditPopup}
      >
        <div>
          <VideoEdit
            videoData={videoData}
            courseId={courseId}
            setEditPopup={setEditPopup}
            videoOrders={particularCourse?.videos}
          />
        </div>
      </DialogModal>

      <DialogModal
        title="Edit Document"
        openPopup={editDocumentOrderPopup}
        setOpenPopup={setEditDocumentOrderPopup}
      >
        <div>
          <EditDocument
            fileData={fileData}
            setEditDocumentOrderPopup={setEditDocumentOrderPopup}
            videoOrders={particularCourse?.videos}
          />
        </div>
      </DialogModal>
    </>
  );
};

IndividualCourse.propTypes = {
  getParticularCourse: PropTypes.func.isRequired,
  getCourseDocuments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  deleteDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  getParticularCourse,
  getCourseDocuments,
  deleteDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualCourse);
