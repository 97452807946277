import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import {
  editDocumentDisplayOrder,
  uploadCourseDocuments,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import FileUpload from "../../../reusableComponents/FileUpload";

const useStyles = makeStyles((theme) => ({
  form: {
    //display: 'flex',
    //flexDirection: 'column',
    //margin: 'auto',
    width: "400px",
    //backgroundColor: '#0009',
    marginTop: theme.spacing(1),
    maxWidth: "500px",
  },

  buttonContainer: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "flex-start",
  },
  formSubmit: {
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    background: "#003399",
    color: "white",
    "&:hover": {
      background: "#003399",
    },
  },
}));

const EditDocument = ({
  editDocumentDisplayOrder,
  uploadCourseDocuments,
  auth,
  fileData,
  setEditDocumentOrderPopup,
  videoOrders,
  courses,
}) => {
  const { uploadSurveyFileProcess, courseDocuments, particularCourse } =
    courses;
  const [defaultOrder, setDefaultOrder] = useState({});
  const visibility = [
    { title: "Hide", value: false },
    { title: "Unhide", value: true },
  ];

  console.log("courses", courses);
  useEffect(() => {
    const defaultOrder = videoOrders.find(
      (item) => item.orderId === fileData.orderId
    );
    //console.log(fileData?.document.name, defaultOrder.title);
    setDefaultOrder(defaultOrder);
    formik.setFieldValue("courseDocumentName", fileData?.document.name);
    formik.setFieldValue("orderId", fileData?.orderId);
    formik.setFieldValue(
      "visibility",
      fileData && fileData.visibility
        ? { title: "Unhide", value: true }
        : { title: "Hide", value: false }
    );
    formik.setFieldValue("courseDocumentData", fileData?.document);
    console.log(" fileData?.document", fileData?.document);
  }, [fileData]);

  useEffect(() => {
    if (!uploadSurveyFileProcess) {
      formik.setFieldValue("courseDocumentData", courseDocuments);
      console.log("konichiva", uploadSurveyFileProcess);
    }
  }, [uploadSurveyFileProcess]);
  const uploadDocuments = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        formik.setFieldValue("courseDocumentName", file.name);

        const formData = new FormData();
        formData.append("courseDocument", file);

        const response = await uploadCourseDocuments(formData);
        if (response && response.data) {
          formik.setFieldValue("courseDocumentData", response.data);
        }
      }
    } catch (error) {
      console.error("Error uploading document:", error);
    } finally {
      if (uploadSurveyFileProcess) {
        uploadCourseDocuments(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      orderId: "",
      courseDocument: "",
      courseDocumentName: "",
      courseDocumentData: fileData?.document || {}, // Default to the existing document
      visibility: {},
    },
    validationSchema: Yup.object({
      orderId: Yup.string().trim().required("Order Id is required field"),
    }),

    onSubmit: async (values) => {
      console.log("values", values);

      let data = { ...values };

      console.log("data", data);
      data.orderId = parseInt(values.orderId);
      data.documentId = fileData._id;
      data.courseDocumentData = values.courseDocumentData || fileData?.document;
      if (isEmpty(data.orderId)) {
        delete data.orderId;
      }
      setEditDocumentOrderPopup(false);
      const documentData = {
        // orderId: data.orderId,
        documentId: data.documentId,
        document: data.courseDocumentData,
        visibility: values?.visibility?.value,
        courseId: particularCourse?._id,
      };
      data.visibility = values?.visibility?.value;
      console.log("documentData", documentData);
      // console.log(values, "sdsd", courseDocuments);
      await editDocumentDisplayOrder(documentData);
    },
  });

  // const uploadDocuments = (e) => {
  //   formik.setFieldValue("courseDocument", e.target.files[0]);
  //   formik.setFieldValue("courseDocumentName", e.target.files[0].name);
  //   // e.preventDefault();

  //   const formData = new FormData();
  //   formData.append("courseDocument", e.target.files[0]);
  //   uploadCourseDocuments(formData);
  // };

  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
      <Grid container spacing={8}>
        {/* <Grid item xs={12}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Select after which video should this appear"
              name="orderId"
              favourite={videoOrders}
              selected={defaultOrder ? defaultOrder : ""}
              onSelect={(video) =>
                formik.setFieldValue("orderId", video.orderId)
              }
              errors={
                formik.touched.orderId && formik.errors.orderId
                  ? formik.errors.orderId
                  : null
              }
            />
          </div>
        </Grid> */}
        <Grid item xs={12}>
          {" "}
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Hide Video"
              label="Hide Video"
              name="visibility"
              favourite={visibility}
              onSelect={(video) => formik.setFieldValue("visibility", video)}
              selected={
                !isEmpty(formik.values.visibility)
                  ? formik.values.visibility && formik.values.visibility
                  : {}
              }
              errors={
                formik.touched.orderId && formik.errors.orderId
                  ? formik.errors.orderId
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {uploadSurveyFileProcess ? (
            <CircularProgress />
          ) : (
            <>
              <FileUpload
                name="courseDocument"
                displayName="Edit_Document"
                fileName={
                  !isEmpty(formik.values.courseDocumentName)
                    ? formik.values.courseDocumentName
                    : "Course Document *"
                }
                accept=".csv,.pdf,.docx"
                onChange={(e) => uploadDocuments(e)}
              />
              {formik.errors.courseDocument ? (
                <span>{formik.errors.courseDocument}</span>
              ) : null}
            </>
          )}
          <div className={classes.buttonContainer}>
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="save"
              disabled={uploadSurveyFileProcess}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

EditDocument.propTypes = {
  editDocumentDisplayOrder: PropTypes.func.isRequired,
  uploadCourseDocuments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = { editDocumentDisplayOrder, uploadCourseDocuments };

export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);
