import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SnackBar } from "../../../reusableComponents/SnackbarConfig";
const RearrangeDocs = ({ history }) => {
  const location = useLocation();
  const { documents, particularCourse, dataType } = location.state || {};
  let { courseId } = useParams();
  const [items, setItems] = useState(() => {
    const filteredData =
      dataType === "document"
        ? documents.filter((doc) => doc.documentType === "Document")
        : documents.filter((doc) => doc.documentType === "Scenarios");

    return filteredData.sort((a, b) => a.orderId - b.orderId);
  });

  const [reordered, setReordered] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
    setReordered(reorderedItems);
  };

  const saveToDatabase = async (reorder) => {
    try {
      let changedOrders = await reorder?.map((item, idx) => {
        //console.log(item.orderId, idx);
        item.orderId = idx;
        return {
          _id: item?._id,
          orderId: idx,
        };
      });

      await axios
        .put(`/api/category/editDocumentOrder`, changedOrders)
        .then((response) => {
          SnackBar.success(response?.data?.message);
        })
        .catch((error) => {
          SnackBar.success(error?.message);
        });

      console.log("Reorder saved successfully", changedOrders);
    } catch (error) {
      console.error("Error saving reordered list to database:", error);
    }

    history.push(`/admin/course/${courseId}`);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <h2>Course Title: {particularCourse?.title}</h2>
          <button
            onClick={() => {
              saveToDatabase(reordered.length > 0 ? reordered : items);
            }}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
        <Droppable droppableId="list2">
          {(provided) => (
            <ol
              {...provided.droppableProps}
              ref={provided.innerRef}
              //   style={{ border: "1px solid black", padding: "auto" }}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item?._id}
                  draggableId={item?._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        border: "1px solid gray",
                        padding: "10px",
                        marginBottom: "8px",
                        backgroundColor: snapshot.isDragging
                          ? "lightblue"
                          : "white",
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item?.document?.name}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default RearrangeDocs;
