import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#003399",
    "&:hover": {
      backgroundColor: "#003399",
    },
    "&.Mui-disabled": {
      backgroundColor: "#b3b3b3", // Gray color for disabled state
      color: "#fff",
    },
  },
}))(MuiButton);

export default function ButtonField({
  name,
  type,
  variant,
  buttonStyle,
  onClick,
  icon,
  component,
  fileTag,
  disabled = false, // Default the disabled prop to false
}) {
  return (
    <ColorButton
      type={type}
      variant={variant || "contained"}
      component={component}
      color="primary"
      className={buttonStyle}
      onClick={onClick}
      disabled={disabled} // Pass disabled prop to ColorButton
    >
      {icon} {name} {fileTag}
    </ColorButton>
  );
}
