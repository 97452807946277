import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import isEmpty from "../../../reusableComponents/is-empty";
import FileUpload from "../../../reusableComponents/FileUpload";
import PhotoUpload from "../../../reusableComponents/PhotoUpload";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  uploadCourseImage,
  clearCourseImage,
  editCourseDetails,
} from "../../../redux/actions/CoursesActions";
import ButtonField from "../../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    //width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  btns: {
    fontWeight: 600,
    color: "white",
    backgroundColor: "#003399",
    //display: "flex",
    // margin: theme.spacing(2),
    //marginTop: "20px",
    // marginLeft: "900px",
    //marginBottom: "36px",
    "&:hover": {
      backgroundColor: "#003399",
      //opacity: "1",
    },
    textfield: {
      maxWidth: "90px",
    },
  },

  formSubmit: {
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    background: "#003399",
    color: "#F8F9F9",
    "&:hover": {
      background: "#003399",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitles = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContents = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActionss = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CourseEdit = ({
  uploadCourseImage,
  courses,
  courseData,
  clearCourseImage,
  editCourseDetails,
  auth,
}) => {
  const classes = useStyles();
  const { courseImage, uploadSurveyFileProcess } = courses;
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      // duration: "",
      courseImage: {},
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Course Name is required field").trim(),
      courseImage: Yup.object({
        link: Yup.string().required("Course Image is required").trim(),
        key: Yup.string().required("Course Image is required").trim(),
        name: Yup.string().required("Course Image is required").trim(),
      }),
      description: Yup.string()
        .required("Description is required field")
        .trim(),
      // duration: Yup.string()
      //   .required("Duration is required field")
      //   .matches(/^[0-9]+$/, "Must be only digits")
      //   .trim(),
    }),
    onSubmit: async (values) => {
      let data = JSON.parse(JSON.stringify(values));
      // data.duration = parseInt(values.duration);
      const { user } = auth;
      data.courseId = courseData._id;
      setOpen(false);
      await editCourseDetails(data);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // formik.setFieldValue(
    //   "duration",
    //   courseData && courseData.duration && courseData.duration.toString()
    //     ? courseData.duration.toString()
    //     : ""
    // );
    formik.setFieldValue(
      "title",
      courseData && courseData.title ? courseData.title : ""
    );
    formik.setFieldValue(
      "description",
      courseData && courseData.description ? courseData.description : ""
    );
    formik.setFieldValue(
      "courseImage",
      courseData &&
        courseData.courseImage &&
        typeof courseData.courseImage !== "string"
        ? courseData.courseImage
        : {}
    );
    clearCourseImage();
  }, [courseData]);

  useEffect(() => {
    if (courseImage) {
      formik.setFieldValue(
        "courseImage",
        courseImage && courseImage ? courseImage : {}
      );
    }
    console.log("inside image", courseImage);
  }, [courseImage]);

  const uploadCoursePoster = (e) => {
    // formik.setFieldValue("courseImage", e.target.files[0]);
    // formik.setFieldValue("courseImageName", e.target.files[0].name);
    // e.preventDefault();

    const formData = new FormData();
    formData.append("courseImage", e.target.files[0]);
    uploadCourseImage(formData);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        className={classes.btns}
      >
        Edit Course Details
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Course Details
        </DialogTitle>

        <DialogContent dividers>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <TextFieldGroup
                  className={classes.textField}
                  label="Course Name"
                  type="text"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  errors={
                    formik.touched.title && formik.errors.title
                      ? formik.errors.title
                      : null
                  }
                />
                <TextFieldGroup
                  label=" Description"
                  type="text"
                  name="description"
                  multiline
                  //rows={7}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  errors={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                />
                {/* <TextFieldGroup
                  label="Duration in minutes"
                  type="text"
                  name="duration"
                  multiline
                  //rows={7}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.duration}
                  errors={
                    formik.touched.duration && formik.errors.duration
                      ? formik.errors.duration
                      : null
                  }
                /> */}
                <Box pb={1}>
                  <Typography variant="h6">Course Image:</Typography>
                </Box>
                {uploadSurveyFileProcess ? (
                  <CircularProgress />
                ) : (
                  <>
                    {/* <FileUpload
              name="courseImage"
              displayName="Upload"
              fileName={
                !isEmpty(formik.values.courseImageName)
                  ? formik.values.courseImageName
                  : "Course Image *"
              }
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e) => uploadCoursePoster(e)}
            />  */}
                    <PhotoUpload
                      name={"courseImage"}
                      className={classes.inputPhoto}
                      imageName={formik.values.courseImage?.name}
                      imageUrl={formik.values.courseImage?.link}
                      htmlFor={"courseImage"}
                      onChange={(e) => uploadCoursePoster(e)}
                    />
                    {formik.errors.courseImage ? (
                      <Box pb={1}>
                        <Typography variant="h7" style={{ color: "red" }}>
                          {formik.errors?.courseImage?.link}
                        </Typography>
                      </Box>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
            <DialogActions>
              <ButtonField
                buttonStyle={classes.formSubmit}
                type="submit"
                name="save"
              />
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

CourseEdit.propTypes = {
  uploadCourseImage: PropTypes.func.isRequired,
  clearCourseImage: PropTypes.func.isRequired,
  editCourseDetails: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  uploadCourseImage,
  clearCourseImage,
  editCourseDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseEdit);
