import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import ButtonField from "../../../reusableComponents/ButtonField";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import {
  getAllCoursesList,
  addCourseDocument,
  uploadCourseDocuments,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import FileUpload from "../../../reusableComponents/FileUpload";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  prerequisite: {
    margin: theme.spacing(2, 0),
  },
  btnContailer: {
    marginTop: theme.spacing(1),
    marginLeft: 750,
  },
  documents: {
    "&:hover": {
      backgroundColor: "#003399",
    },
  },
  btnSubmit: {
    borderRadius: "16px",
    backgroundColor: "#003399",
    //color: "#F8F9F9",
    color: "white",
    //opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      //opacity: "1",
    },
  },
}));

const DocumentForm = ({
  auth,
  courses,
  getAllCoursesList,
  addCourseDocument,
  uploadCourseDocuments,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      //   orderId: "",
      //   title: "",
      //   description: "",
      //   embedUrl: "",
      documentType: "Document",
      courseDocument: "",
      courseDocumentName: "",
      relatedCourse: {},
    },
    validationSchema: Yup.object({
      relatedCourse: Yup.object().required("Please select a course"),
      courseDocument: Yup.string().required("Please Upload a document"),
    }),
    onSubmit: async (values) => {
      let documentData = JSON.parse(JSON.stringify(values));
      documentData.courseId = values.relatedCourse._id;
      documentData.document = {
        key: courseDocuments.key,
        link: courseDocuments.link,
        name: courseDocuments.name,
      };

      // console.log(documentData);

      await addCourseDocument(documentData);
    },
  });

  const { isAuthenticated } = auth;
  const { allCoursesList, uploadSurveyFileProcess, courseDocuments } = courses;
  const [videoOrderList, setVideoOrderList] = useState([]);

  useEffect(() => {
    if (isEmpty(allCoursesList) && isAuthenticated) {
      getAllCoursesList();
    }
  }, [allCoursesList, isAuthenticated]);

  useEffect(() => {
    // console.log(formik.values.relatedCourse);
    if (formik.values?.relatedCourse?.videos) {
      setVideoOrderList(formik.values.relatedCourse?.videos);
    } else {
      setVideoOrderList([]);
    }
  }, [formik.values.relatedCourse]);

  const uploadDocuments = (e) => {
    formik.setFieldValue("courseDocument", e.target.files[0]);
    formik.setFieldValue("courseDocumentName", e.target.files[0].name);
    // e.preventDefault();

    const formData = new FormData();
    formData.append("courseDocument", e.target.files[0]);
    uploadCourseDocuments(formData);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Related Course"
              name="relatedCourse"
              favourite={allCoursesList}
              onSelect={(course) =>
                formik.setFieldValue("relatedCourse", course)
              }
              // selected={formik.values.relatedCourse}
              errors={
                formik.touched.relatedCourse && formik.errors.relatedCourse
                  ? formik.errors.relatedCourse
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          {uploadSurveyFileProcess ? (
            <CircularProgress />
          ) : (
            <>
              <FileUpload
                name="courseDocument"
                displayName="UploadDocument"
                className={classes.documents}
                fileName={
                  !isEmpty(formik.values.courseDocumentName)
                    ? formik.values.courseDocumentName
                    : "Course Document *"
                }
                accept=".csv,.pdf,.docx,.ppt"
                onChange={(e) => uploadDocuments(e)}
              />
              {formik.errors.courseDocument ? (
                <span>{formik.errors.courseDocument}</span>
              ) : null}
            </>
          )}
        </Grid>
        {/* <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Select after which video should this appear"
              name="orderId"
              favourite={videoOrderList}
              onSelect={(video) =>
                formik.setFieldValue("orderId", video.orderId)
              }
              errors={
                formik.touched.orderId && formik.errors.orderId
                  ? formik.errors.orderId
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ paddingTop: "7px" }}>
            <SelectSingleValues
              placeholder="Prerequisite"
              name="orderId"
              favourite={videoOrderList}
              onSelect={(video) =>
                formik.setFieldValue("orderId", video.orderId)
              }
              // errors={
              //   formik.touched.orderId && formik.errors.orderId
              //     ? formik.errors.orderId
              //     : null
              // }
            />
          </div>
        </Grid> */}
        <Grid item xs={6}>
          <div>
            <SelectSingleValues
              placeholder="Document Type"
              name="documentType"
              // selected={{ title: "Document" }}
              favourite={[{ title: "Document" }, { title: "Scenarios" }]}
              onSelect={(type) =>
                formik.setFieldValue("documentType", type.title)
              }
              // errors={
              //   formik.touched.orderId && formik.errors.orderId
              //     ? formik.errors.orderId
              //     : null
              // }
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.btnContailer}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Submit"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

DocumentForm.propTypes = {
  getAllCoursesList: PropTypes.func.isRequired,
  DocumentForm: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  uploadCourseDocuments: PropTypes.func.isRequired,
  addCourseDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  getAllCoursesList,
  DocumentForm,
  uploadCourseDocuments,
  addCourseDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
