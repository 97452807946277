import React from "react";
import { Switch } from "react-router-dom";

import AdminLayout from "./components/Admin_View/Layout/AdminLayout";

import PrivateRoute from "./PrivateRoute";

import Dashboard from "./components/Admin_View/Dashboard";

import Users from "./components/Admin_View/Accounts/Users";
import User from "./components/Admin_View/Accounts/User";
import AdminUsers from "./components/Admin_View/Accounts/AdminUsers";

import NewCourse from "./components/Admin_View/AddNewCourse/NewCourse";
import NewVideo from "./components/Admin_View/AddNewVideo/NewVideo";

import Courses from "./components/Admin_View/Course/Courses";
import IndividualCourse from "./components/Admin_View/Course/IndividualCourse";
import AccessLogs from "./components/Admin_View/Accounts/AccessLogs";
import UserEditProfile from "./components/UserProfile/UserEditProfile";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import AddDocuments from "./components/Admin_View/AddDocuments/Documents";
import Programs from "./components/Admin_View/Programs/Programs";
import NewProgram from "./components/Admin_View/Programs/NewProgram";
import AddScenarios from "./components/Admin_View/AddScenarios/Scenarios";
import Coach from "./components/Admin_View/Accounts/Coach";
import Rearrange from "./components/Admin_View/Course/Rearrange";
import RearrangeDocs from "./components/Admin_View/Course/RearrangeDocs";
//import ResetPassword from './components/ForgotPassword/ResetPassword';

const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <AdminLayout>
        <PrivateRoute path={`${match.url}/dashboard`} component={Dashboard} />
        <PrivateRoute path={`${match.url}/users`} component={Users} />
        <PrivateRoute path={`${match.url}/user/:userId`} component={User} />
        <PrivateRoute path={`${match.url}/adminUsers`} component={AdminUsers} />
        <PrivateRoute path={`${match.url}/coach`} component={Coach} />
        <PrivateRoute path={`${match.url}/newCourse`} component={NewCourse} />
        <PrivateRoute path={`${match.url}/newProgram`} component={NewProgram} />
        <PrivateRoute path={`${match.url}/newVideo`} component={NewVideo} />
        <PrivateRoute path={`${match.url}/courses`} component={Courses} />
        <PrivateRoute
          path={`${match.url}/addDocuments`}
          component={AddDocuments}
        />
        <PrivateRoute
          path={`${match.url}/addScenarios`}
          component={AddScenarios}
        />
        <PrivateRoute path={`${match.url}/programs`} component={Programs} />
        <PrivateRoute
          path={`${match.url}/course/:courseId`}
          component={IndividualCourse}
        />
        <PrivateRoute path={`${match.url}/accesslog`} component={AccessLogs} />
        <PrivateRoute
          path={`${match.url}/editProfile`}
          component={UserEditProfile}
        />
        <PrivateRoute
          path={`${match.url}/passwordReset`}
          component={PasswordReset}
        />
        <PrivateRoute
          path={`${match.url}/rearrangeCourse/:courseId`}
          component={Rearrange}
        />
        <PrivateRoute
          path={`${match.url}/RearrangeDocs/:courseId`}
          component={RearrangeDocs}
        />
      </AdminLayout>
    </Switch>
  );
};

export default AdminRoutes;
